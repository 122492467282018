<template>
    <div class="main">
        <span class="arrUp" @click.stop="clickUp" :class="{'active':sortOrderParams===1}"></span>
        <span class="arrDown" @click.stop="clickDown" :class="{'active':sortOrderParams===2}"></span>
    </div>
</template>

<script>
export default {
    name: "SmallTriangle",
    data() {
        return {
            sortOrderParams: this.sortOrder,
        }
    },
    props: {
        sortOrder: {
            type: Number,
        },
    },
    watch: {
        sortOrder: {
            handler(newVal, oldVal) {
                this.sortOrderParams = newVal
            },
            deep: true,
            immediate: true,
        }

    },

    methods: {
        clickUp() {
            this.sortOrderParams = this.sortOrderParams === 1 ? 0 : 1;
            this.$emit('clickTriangle', this.sortOrderParams);
        }, 

        clickDown() {
            this.sortOrderParams = this.sortOrderParams === 2 ? 0 : 2;
            this.$emit('clickTriangle', this.sortOrderParams);
        }, 
    }
}
</script>

<style scoped>

.main {
  display: inline-flex;
  flex-direction: column;
  margin-left: 5px;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid #a5a5a5;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 3px;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 6px solid #a5a5a5;
  cursor: pointer;
}
.active{
  border-bottom-color: #1592e6;
  border-top-color: #1592e6;
}

</style>


